import React from "react";

export const Modal = ({ children }) => <div className="fixed top-0 left-0 w-full h-full  bg-black bg-opacity-50">
    <div className="flex flex-col h-full">
        <div className="grow flex justify-center items-center">
            <div className="m-4 bg-white" style={{ minWidth: 450 }}>
                {children}
            </div>
        </div>
    </div>
</div>

export const ModalTitle = ({ children }) => <div className="p-4 text-lg border-b-2 border-mwpurple uppercase">
    {children}
</div>

export const ModalContent = ({ children }) => <div className="p-4">{children}</div>