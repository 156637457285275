import React, { useState } from "react";
import { Outlet, useParams } from "react-router";
import { history } from "./app";
import { setRSSFeedUrl, setTopicUrlName } from "./edit-embed";
import { Button } from "./utils/button";
import { Title } from "./utils/layout";

export const CreatePage = () => <React.Fragment>
    <Title>Create a new embed</Title>
    <Outlet />
</React.Fragment>

export const CreateChooseType = () => {
    const [embedType, setType] = useState("topic")
    return <div>
        <div>
            Please choose your embed type:
            <select onChange={(e) => setType(e.target.value)} value={embedType} className="px-3 py-1.5 border border-purple-300 rounded-sm">
                <option value={"topic"}>Scoop.it Topic</option>
                <option value={"RSS"}>RSS feed</option>
            </select>
        </div>
        <div>
            <Button cta={true} onClick={() => history.push(`/create/configure/${embedType}`)}>Next</Button>
        </div>
    </div >
}

export const CreateConfigure = () => {
    const { embedType } = useParams();
    const [value, setValue] = useState("");
    return <div className="flex flex-col gap-2">
        <div>
            {embedType == "topic" ? "Urlname of the topic:" : "URL of the RSS feed: "}
        </div>
        <div>
            <input type="text" value={value} onChange={e => setValue(e.target.value)} />
        </div>
        <div>
            <Button cta={true} onClick={() => {
                if (embedType === "topic") {
                    history.push(`/edit?type=topic&topic=${encodeURIComponent(value)}`);
                } else {
                    history.push(`/edit?type=rss&rss=${encodeURIComponent(value)}`);
                }
            }}>Finish</Button>
        </div>
    </div>
}