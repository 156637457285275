import React from "react";
import classname from "classname";

export const Button = ({
    onClick, cta = false, children
}) => <div className={classname("cursor-pointer px-4 py-2 bg-gray-400 text-white hover:bg-gray-600 inline-block rounded-sm", {
    "bg-mwteal hover:bg-mwdarkteal": cta
})}
    onClick={onClick}
>{children}</div>

