import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Outlet, Route, Router, Routes } from "react-router";
import { BrowserRouter, Link } from "react-router-dom";
import { CheckAuth, authenticated } from "./utils/auth";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Header } from "./header";
import { Button } from "./utils/button";
import { CreateChooseType, CreateConfigure, CreatePage } from "./create";
import { EditEmbed, setEmbedStudioLoaded, setEmbedStudioUrl } from "./edit-embed";

export const history = createBrowserHistory();

const App = () => {
    return <HistoryRouter history={history}>
        <Routes>
            <Route element={<PageLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/create" element={<CreatePage />} >
                    <Route path="choose-type" element={<CreateChooseType />} />
                    <Route path="configure/:embedType" element={<CreateConfigure />} />
                </Route>
                <Route path="/edit" element={<EditEmbed />} />
            </Route>
        </Routes>
    </HistoryRouter>
}

const AuthenticatedOutlet = authenticated(Outlet);

const PageLayout = () => <React.Fragment>
    <Header />
    <CheckAuth />
    <div className="px-4 pt-4">
        <div className="flex flex-col gap-2">
            <AuthenticatedOutlet />
        </div>
    </div>
</React.Fragment>

export const Home = () => <div>Click on the <Link to={"/create/choose-type"} ><Button cta={true}>Create</Button></Link> button to start.</div>

// load external embed studio
fetch("/api/config.json")
    .then(response => response.json())
    .then(config => {
        const styles = document.createElement("link");
        styles.setAttribute("rel", "stylesheet");
        styles.setAttribute("type", "text/css");
        styles.setAttribute("href", config.embed_studio_css_url);
        document.body.append(styles);
        const scriptElement = document.createElement("script");
        scriptElement.onload = function () {
            setEmbedStudioLoaded();
        }
        scriptElement.setAttribute("async", true);
        scriptElement.setAttribute("src", config.embed_studio_js_url);
        document.body.append(scriptElement);
        // render the APP
        ReactDOM.render(<App />, document.getElementById("app"));
        setEmbedStudioUrl(config.embed_studio_url);
    })



