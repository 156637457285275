import { clearToken, getToken } from "./auth"

/**
 * get the given url. Assumes the user is authenticated.
 */
export async function get(url, token) {
    if (token == null) {
        throw "User must be authenticated"
    }
    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    if (!response.ok) {
        // if the response is 403, let's automatically logout the user
        if (response.status == 403) {
            clearToken();
        }
        throw `HTTP error received: ${response.status} ${response.statusText}`
    }
    return response;
}

export async function post(url, body, token) {
    const headers = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await fetch(url, {
        headers,
        method: "POST",
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        // if the response is 403, let's automatically logout the user
        if (response.status == 403) {
            clearToken();
        }
        throw `HTTP error received: ${response.status} ${response.statusText}`
    }
    return response;
}
