import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { ifAuthenticated, ifUnAuthenticated, LoginButton, setToken } from "./utils/auth";
import { Button } from "./utils/button";
const logo = new URL('img/logo-scoop-it-black_cyan.svg', import.meta.url);

export const Header = () => <div className="flex gap-2 p-4 shadow-md bg-mwlightblue items-center text-mwcharcoal">
    <div>
        <Link to={"/"}>
            <img src={logo} style={{ width: 150, height: "auto" }} />
        </Link>
    </div>
    <div>
        <h1 className="m-0 ml-4 text-4xl">Beautiful Embed Creator</h1>
    </div>
    <div className="grow"></div>
    <div >
        <Login />
        <User />
    </div>
</div>

const Login = ifUnAuthenticated(LoginButton);

const Logout = ifAuthenticated(() => <Button onClick={() => setToken(null)}>logout</Button>)

const User = ifAuthenticated(({ uid }) => <div>Logged as {uid} <Logout /></div>)
