import React, { useEffect, useRef, useState } from "react";
import { decodeJwt } from "jose";
import { Store } from "pullstate";
import { Button } from "./button";
import { Modal, ModalContent, ModalTitle } from "./modal";
import { get, post } from "./request";



const LOCAL_STORAGE_TOKEN_KEY = "scoopitBeautyfulEmbedCreatorToken";
const tokenStore = new Store({
    token: window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
});

export const useToken = function () {
    return tokenStore.useState(s => s.token);
}

/**
 * Get authentication token, this can be used outside React context.
 * 
 * @returns 
 */
export const getToken = function () {
    return tokenStore.getRawState().token;
}

export const setToken = function (token) {
    if (token == null) {
        clearToken();
        return;
    }
    tokenStore.update(function (state) {
        state.token = token;
    });
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
}

export const clearToken = function () {
    tokenStore.update(function (state) {
        state.token = null;
    });
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)

}

export const getUid = function (token) {
    if (token == null) {
        return null;
    }
    return decodeJwt(token).sub;
}

export const authenticated = Component => props => {
    const token = useToken();
    if (token != null) {
        return <Component {...props} uid={getUid(token)} />
    } else {
        return <LoginModal />
    }
}

export const LoginModal = () => {
    const [error, setError] = useState(null);
    const buttonRef = useRef(null);
    useEffect(() => {
        google.accounts.id.initialize({
            client_id: "453778511303-qpsd8ovtrc905212dhkfpe35maoe7kpn.apps.googleusercontent.com",
            context: "signin",
            ux_mode: "popup",
            callback: function (arg) {
                console.log(arg);
                post("/api/login-with-google", {
                    credential: arg.credential
                })
                    .then(response => response.json())
                    .then(json => setToken(json.token))
                    .catch(e => setError(e))
            },
        });
        const parent = document.getElementById('g_id_signin');
        google.accounts.id.renderButton(buttonRef.current, {
            type: "standard",
            shape: "rectangular",
            theme: "outline",
            text: "signin_with",
            size: "large",
            logo_alignment: "left"
        });
    });
    return <Modal>
        <ModalTitle>Login</ModalTitle>
        <ModalContent>
            <div className="flex flex-col gap-4">
                <div>
                    <div>
                        Please Login with your <b>scoop.it</b> or <b>meltwater.com</b> google account.
                    </div>
                    {error &&
                        <div className="text-sm text-red-600">{error}</div>
                    }
                </div>
                <div className="flex gap-4 justify-end">
                    <div ref={buttonRef}></div>
                </div>
            </div>
        </ModalContent>
    </Modal>
}

export const ifAuthenticated = Component => props => {
    const token = useToken();
    if (token != null) {
        return <Component {...props} uid={getUid(token)} />
    } else {
        return null;
    }
}

export const ifUnAuthenticated = Component => props => {
    const token = useToken();
    if (token == null) {
        return <Component {...props} />
    } else {
        return null;
    }
}

export const LoginButton = () => <Button cta={true}>Login</Button>

export const CheckAuth = ifAuthenticated(() => {
    useEffect(() => {
        get("/api/auth-check", getToken());
    });
    return null;
})