import { Store } from "pullstate"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { Home } from "./app";
import { getToken } from "./utils/auth";
import { Title } from "./utils/layout";

const ConfigureStore = new Store({
    embedStudioUrl: null,
    embedStudioLoaded: false,
});


export const setEmbedStudioUrl = function (url) {
    ConfigureStore.update(function (state) {
        state.embedStudioUrl = url;
    })
}

export const setEmbedStudioLoaded = function () {
    ConfigureStore.update(function (state) {
        state.embedStudioLoaded = true;
    });
}

export const EditEmbed = () => {
    const embedStudioUrl = ConfigureStore.useState(s => s.embedStudioUrl);
    const embedStudioLoaded = ConfigureStore.useState(s => s.embedStudioLoaded);
    let [searchParams, setSearchParams] = useSearchParams();
    const embedType = searchParams.get("type");


    useEffect(function () {
        if (embedStudioUrl === null || embedType === null || !embedStudioLoaded) {
            return;
        }
        const embedSource = {}
        if (embedType === "rss") {
            embedSource.rss = searchParams.get("rss");
        } else if (embedType === "topic") {
            embedSource.topic = searchParams.get("topic");
        } else {
            console.error(`Invalid embed type ${embedType}`);
        }
        console.log(embedSource)
        renderEmbedStudio(document.getElementById("embed-studio"), {
            fetchToken: function (sub) {
                return fetch("/api/embed-token", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        "Authorization": `Bearer ${getToken()}`
                    },
                    body: JSON.stringify({
                        sub
                    })
                }).then(function (response) {
                    return response.json();
                }).then(function (json) {
                    return json.token;
                });
            },
            baseUrl: embedStudioUrl,
            embedSource,
            locale: "en",
            allowWhitelabel: true
        });
    });

    if (embedType === null) {
        return <Home />
    }
    if (embedStudioUrl === null) {
        return <div className="text-red-600">Something did wrong (embedStudioUrl===null)... please check the console!</div>
    }
    const embedTitleDesc = embedType === "rss" ? searchParams.get("rss") : searchParams.get("topic");
    return <React.Fragment>
        <div className="flex justify-between">
            <Title>Customize your embed</Title>
            <div>{embedTitleDesc}</div>
        </div>

        <div id="embed-studio"></div>
    </React.Fragment>

}